import { HEX } from "../Colors/types";
import mix from "../Colors/mix";
import { basicDesignTokens } from "../DesignTokens/designTokens";

type Semantic = typeof basicDesignTokens.dynamicColorDark.semantic;
export const getSemanticColors = (semantic: Semantic) => {
  const activePrimary = semantic.active.primary as HEX;
  const alertPrimary = semantic.alert.primary as HEX;
  const warningPrimary = semantic.warning.primary as HEX;
  const successPrimary = semantic.success.primary as HEX;
  const aiPrimary = semantic.ai.primary as HEX;

  return {
    // active
    activePrimary,
    activeDarken10: mix(semantic.mix["darken-10"] as HEX, activePrimary),
    activeDarken20: mix(semantic.mix["darken-20"] as HEX, activePrimary),
    activeLighten50: mix(semantic.mix["lighten-50"] as HEX, activePrimary),
    activeLighten80: mix(semantic.mix["lighten-80"] as HEX, activePrimary),
    activeLighten90: mix(semantic.mix["lighten-90"] as HEX, activePrimary),

    // alert
    alertPrimary,
    alertDarken10: mix(semantic.mix["darken-10"] as HEX, alertPrimary),
    alertDarken20: mix(semantic.mix["darken-20"] as HEX, alertPrimary),
    alertLighten50: mix(semantic.mix["lighten-50"] as HEX, alertPrimary),
    alertLighten80: mix(semantic.mix["lighten-80"] as HEX, alertPrimary),
    alertLighten90: mix(semantic.mix["lighten-90"] as HEX, alertPrimary),

    // warning
    warningPrimary,
    warningDarken10: mix(semantic.mix["darken-10"] as HEX, warningPrimary),
    warningDarken20: mix(semantic.mix["darken-20"] as HEX, warningPrimary),
    warningLighten50: mix(semantic.mix["lighten-50"] as HEX, warningPrimary),
    warningLighten80: mix(semantic.mix["lighten-80"] as HEX, warningPrimary),
    warningLighten90: mix(semantic.mix["lighten-90"] as HEX, warningPrimary),

    // success
    successPrimary,
    successDarken10: mix(semantic.mix["darken-10"] as HEX, successPrimary),
    successDarken20: mix(semantic.mix["darken-20"] as HEX, successPrimary),
    successLighten50: mix(semantic.mix["lighten-50"] as HEX, successPrimary),
    successLighten80: mix(semantic.mix["lighten-80"] as HEX, successPrimary),
    successLighten90: mix(semantic.mix["lighten-90"] as HEX, successPrimary),

    // ai
    aiPrimary,
    aiDarken10: mix(semantic.mix["darken-10"] as HEX, semantic.ai.primary as HEX),
    aiDarken20: mix(semantic.mix["darken-20"] as HEX, semantic.ai.primary as HEX),
    aiLighten50: mix(semantic.mix["lighten-50"] as HEX, semantic.ai.primary as HEX),
    aiLighten80: mix(semantic.mix["lighten-80"] as HEX, semantic.ai.primary as HEX),
    aiLighten90: mix(semantic.mix["lighten-90"] as HEX, semantic.ai.primary as HEX),

    primaryTextOnLight: semantic.text["on-light"].primary as HEX,
    secondaryTextOnLight: semantic.text["on-light"].secondary as HEX,
    hintTextOnLight: semantic.text["on-light"].hint as HEX,
    placeholderTextOnLight: semantic.text["on-light"].placeholder as HEX,
    disabledTextOnLight: semantic.text["on-light"].disabled as HEX,

    primaryTextOnDark: semantic.text["on-dark"].primary as HEX,
    secondaryTextOnDark: semantic.text["on-dark"].secondary as HEX,
    hintTextOnDark: semantic.text["on-dark"].hint as HEX,
    placeholderTextOnDark: semantic.text["on-dark"].placeholder as HEX,
    disabledTextOnDark: semantic.text["on-dark"].disabled as HEX,

    stroke: semantic.element.stroke as HEX,
    strokeDarken: semantic.element["stroke-darken"] as HEX,
    strokeLighten: semantic.element["stroke-lighten"] as HEX,
    divider: semantic.element.divider as HEX,
    dividerLighten: semantic.element["divider-lighten"] as HEX,
    surface: semantic.element.surface as HEX,
    surfaceDarken: semantic.element["surface-darken"] as HEX,
    surfaceOver: semantic.element["surface-over"] as HEX,
    subtleBlack: semantic.element["subtle-black"] as HEX,
    subtleWhite: semantic.element["subtle-white"] as HEX,
    focusIndicator: semantic.element["focus-indicator"] as HEX,
  } as const;
};
