/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState } from "react";
import { Tooltip } from "@happeouikit/tooltip";
import { Button } from "./Button";
import { IconButtonProps } from "./Button.types";

const randomId = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { icon, "aria-label": ariaLabel, defaultTooltip, ...rest } = props;
    const [randomTooltipId] = useState(() => `tooltip-${randomId()}`);
    const defaultTooltipEnabled = defaultTooltip !== false;
    return (
      <>
        <Button
          iconButton
          ref={ref}
          data-tip={defaultTooltipEnabled ? ariaLabel : undefined}
          data-for={defaultTooltipEnabled ? randomTooltipId : undefined}
          data-tooltip-content={defaultTooltipEnabled ? ariaLabel : undefined}
          data-tooltip-id={defaultTooltipEnabled ? randomTooltipId : undefined}
          {...rest}
        >
          {icon}
        </Button>
        {defaultTooltipEnabled && <Tooltip id={randomTooltipId} />}
      </>
    );
  }
);
