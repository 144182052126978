import { jsx as a } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M17.855 2.122c.029-.163.261-.163.29 0a4.603 4.603 0 0 0 3.733 3.733c.163.029.163.261 0 .29a4.603 4.603 0 0 0-3.733 3.733c-.029.163-.261.163-.29 0a4.603 4.603 0 0 0-3.733-3.733c-.163-.029-.163-.261 0-.29a4.603 4.603 0 0 0 3.733-3.733ZM10.466 5.105a4.5 4.5 0 1 0 3.36 5.635 1 1 0 0 1 1.922.552 6.5 6.5 0 0 1-1 2.042l5.55 5.55a1 1 0 0 1-1.413 1.415l-5.55-5.55a6.501 6.501 0 1 1-2.44-11.597 1 1 0 0 1-.429 1.953Z" })
  }
);
export {
  c as default
};
