import { jsxs as t, jsx as a } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M20.145 2.122c-.029-.163-.261-.163-.29 0a4.603 4.603 0 0 1-3.733 3.733c-.163.029-.163.261 0 .29a4.603 4.603 0 0 1 3.733 3.733c.029.163.261.163.29 0a4.603 4.603 0 0 1 3.733-3.733c.163-.029.163-.261 0-.29a4.603 4.603 0 0 1-3.733-3.733ZM3 7a2 2 0 0 1 2-2h7.5a1 1 0 1 1 0 2H5v10h14v-3a1 1 0 1 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7Z" }),
      /* @__PURE__ */ a("path", { d: "M16 11H7V9h9v2ZM16 14H7v-2h9v2Z" })
    ]
  }
);
export {
  e as default
};
