import { jsxs as t, jsx as a } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ a("path", { d: "M20.145 2.122c-.029-.163-.261-.163-.29 0a4.603 4.603 0 0 1-3.733 3.733c-.163.029-.163.261 0 .29a4.603 4.603 0 0 1 3.733 3.733c.029.163.261.163.29 0a4.603 4.603 0 0 1 3.733-3.733c.163-.029.163-.261 0-.29a4.603 4.603 0 0 1-3.733-3.733Z" }),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 5h9.233c-.584 1.165-.07 2.83 1.541 3.115a2.602 2.602 0 0 1 2.111 2.11c.284 1.612 1.95 2.126 3.115 1.542V17a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2Zm3 6h7a1 1 0 1 0 0-2H8a1 1 0 0 0 0 2Zm0 3h7a1 1 0 1 0 0-2H8a1 1 0 1 0 0 2Z"
        }
      )
    ]
  }
);
export {
  h as default
};
