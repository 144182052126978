// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---doc-pages-index-mdx": () => import("./../../../../doc-pages/index.mdx" /* webpackChunkName: "component---doc-pages-index-mdx" */),
  "component---packages-actions-menu-docs-menus-mdx": () => import("./../../../../packages/actions-menu/docs/menus.mdx" /* webpackChunkName: "component---packages-actions-menu-docs-menus-mdx" */),
  "component---packages-alerts-docs-alerts-mdx": () => import("./../../../../packages/alerts/docs/alerts.mdx" /* webpackChunkName: "component---packages-alerts-docs-alerts-mdx" */),
  "component---packages-avatar-docs-avatar-mdx": () => import("./../../../../packages/avatar/docs/avatar.mdx" /* webpackChunkName: "component---packages-avatar-docs-avatar-mdx" */),
  "component---packages-banners-docs-banners-mdx": () => import("./../../../../packages/banners/docs/banners.mdx" /* webpackChunkName: "component---packages-banners-docs-banners-mdx" */),
  "component---packages-breadcrumbs-docs-breadcrumbs-mdx": () => import("./../../../../packages/breadcrumbs/docs/breadcrumbs.mdx" /* webpackChunkName: "component---packages-breadcrumbs-docs-breadcrumbs-mdx" */),
  "component---packages-buttons-docs-buttons-mdx": () => import("./../../../../packages/buttons/docs/buttons.mdx" /* webpackChunkName: "component---packages-buttons-docs-buttons-mdx" */),
  "component---packages-buttons-ds-3-docs-buttons-mdx": () => import("./../../../../packages/buttons-ds3/docs/buttons.mdx" /* webpackChunkName: "component---packages-buttons-ds-3-docs-buttons-mdx" */),
  "component---packages-card-docs-01-card-mdx": () => import("./../../../../packages/card/docs/01-card.mdx" /* webpackChunkName: "component---packages-card-docs-01-card-mdx" */),
  "component---packages-color-picker-docs-color-picker-mdx": () => import("./../../../../packages/color-picker/docs/color-picker.mdx" /* webpackChunkName: "component---packages-color-picker-docs-color-picker-mdx" */),
  "component---packages-colors-docs-colors-mdx": () => import("./../../../../packages/colors/docs/colors.mdx" /* webpackChunkName: "component---packages-colors-docs-colors-mdx" */),
  "component---packages-content-renderer-docs-content-renderer-mdx": () => import("./../../../../packages/content-renderer/docs/content-renderer.mdx" /* webpackChunkName: "component---packages-content-renderer-docs-content-renderer-mdx" */),
  "component---packages-copy-to-clipboard-button-docs-copy-to-clipboard-button-mdx": () => import("./../../../../packages/copy-to-clipboard-button/docs/copy-to-clipboard-button.mdx" /* webpackChunkName: "component---packages-copy-to-clipboard-button-docs-copy-to-clipboard-button-mdx" */),
  "component---packages-emojis-docs-emojis-mdx": () => import("./../../../../packages/emojis/docs/emojis.mdx" /* webpackChunkName: "component---packages-emojis-docs-emojis-mdx" */),
  "component---packages-file-icon-docs-file-icon-mdx": () => import("./../../../../packages/file-icon/docs/file-icon.mdx" /* webpackChunkName: "component---packages-file-icon-docs-file-icon-mdx" */),
  "component---packages-file-picker-docs-file-picker-mdx": () => import("./../../../../packages/file-picker/docs/file-picker.mdx" /* webpackChunkName: "component---packages-file-picker-docs-file-picker-mdx" */),
  "component---packages-form-elements-docs-form-elements-mdx": () => import("./../../../../packages/form-elements/docs/form-elements.mdx" /* webpackChunkName: "component---packages-form-elements-docs-form-elements-mdx" */),
  "component---packages-icons-docs-app-mdx": () => import("./../../../../packages/icons/docs/app.mdx" /* webpackChunkName: "component---packages-icons-docs-app-mdx" */),
  "component---packages-icons-docs-file-mdx": () => import("./../../../../packages/icons/docs/file.mdx" /* webpackChunkName: "component---packages-icons-docs-file-mdx" */),
  "component---packages-icons-docs-flag-mdx": () => import("./../../../../packages/icons/docs/flag.mdx" /* webpackChunkName: "component---packages-icons-docs-flag-mdx" */),
  "component---packages-icons-docs-legacy-icons-mdx": () => import("./../../../../packages/icons/docs/legacy-icons.mdx" /* webpackChunkName: "component---packages-icons-docs-legacy-icons-mdx" */),
  "component---packages-icons-docs-symbol-mdx": () => import("./../../../../packages/icons/docs/symbol.mdx" /* webpackChunkName: "component---packages-icons-docs-symbol-mdx" */),
  "component---packages-image-carousel-docs-image-carousel-mdx": () => import("./../../../../packages/image-carousel/docs/image-carousel.mdx" /* webpackChunkName: "component---packages-image-carousel-docs-image-carousel-mdx" */),
  "component---packages-image-cropper-docs-image-cropper-mdx": () => import("./../../../../packages/image-cropper/docs/image-cropper.mdx" /* webpackChunkName: "component---packages-image-cropper-docs-image-cropper-mdx" */),
  "component---packages-image-grid-docs-image-grid-mdx": () => import("./../../../../packages/image-grid/docs/image-grid.mdx" /* webpackChunkName: "component---packages-image-grid-docs-image-grid-mdx" */),
  "component---packages-layout-docs-layout-mdx": () => import("./../../../../packages/layout/docs/layout.mdx" /* webpackChunkName: "component---packages-layout-docs-layout-mdx" */),
  "component---packages-list-docs-list-mdx": () => import("./../../../../packages/list/docs/list.mdx" /* webpackChunkName: "component---packages-list-docs-list-mdx" */),
  "component---packages-loaders-docs-loaders-mdx": () => import("./../../../../packages/loaders/docs/loaders.mdx" /* webpackChunkName: "component---packages-loaders-docs-loaders-mdx" */),
  "component---packages-modals-docs-modals-mdx": () => import("./../../../../packages/modals/docs/modals.mdx" /* webpackChunkName: "component---packages-modals-docs-modals-mdx" */),
  "component---packages-navigation-docs-navigation-mdx": () => import("./../../../../packages/navigation/docs/navigation.mdx" /* webpackChunkName: "component---packages-navigation-docs-navigation-mdx" */),
  "component---packages-theme-docs-theme-mdx": () => import("./../../../../packages/theme/docs/theme.mdx" /* webpackChunkName: "component---packages-theme-docs-theme-mdx" */),
  "component---packages-toast-docs-toast-mdx": () => import("./../../../../packages/toast/docs/toast.mdx" /* webpackChunkName: "component---packages-toast-docs-toast-mdx" */),
  "component---packages-typography-docs-typography-mdx": () => import("./../../../../packages/typography/docs/typography.mdx" /* webpackChunkName: "component---packages-typography-docs-typography-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

