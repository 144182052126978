import { jsxs as l, jsx as a } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ a("path", { d: "M5.855 2.122c.029-.163.261-.163.29 0a4.602 4.602 0 0 0 3.733 3.733c.163.029.163.261 0 .29a4.602 4.602 0 0 0-3.733 3.733c-.029.163-.261.163-.29 0a4.602 4.602 0 0 0-3.733-3.733c-.163-.029-.163-.261 0-.29a4.602 4.602 0 0 0 3.733-3.733Z" }),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 22a.967.967 0 0 1-.713-.288A.968.968 0 0 1 4 21v-2.425a1.975 1.975 0 0 1 .575-1.4L17.2 4.575c.2-.183.42-.325.663-.425.241-.1.495-.15.762-.15s.525.05.775.15c.25.1.467.25.65.45L21.425 6c.2.183.346.4.438.65a2.165 2.165 0 0 1 0 1.512 1.874 1.874 0 0 1-.438.663l-12.6 12.6a1.975 1.975 0 0 1-1.4.575H5Zm1-2h1.425l9.775-9.775-.725-.7-.7-.725L6 18.575V20Z"
        }
      )
    ]
  }
);
export {
  c as default
};
