import React from "react";
import styled, { css } from "styled-components";
import ReactTooltip, { TooltipProps as ReactTooltipProps } from "react-tooltip";
import { gray01, white } from "@happeouikit/colors";
import { BodyUI } from "@happeouikit/typography";

interface TooltipProps extends Omit<ReactTooltipProps, "place"> {
  small?: boolean;
  id?: string;
  place?: "top" | "right" | "bottom" | "left" | "bottom-start" | "bottom-end" | "top-start" | "top-end";
  content?: React.ReactNode;
  /**
   * As an alternative to matching id and an anchor element's
   * data-for/data-tooltip-id, you can use this anchorSelect with CSS selectors.
   *
   * See examples: https://react-tooltip.com/docs/examples/anchor-select
   * */
  anchorSelect?: string;
  render?: (args: { content: string | null; activeAnchor: HTMLElement | null }) => React.ReactNode;
  className?: string;
  delayHide?: number;
  delayShow?: number;

  /** @deprecated, default is now solid */
  effect?: "float" | "solid";
  /** @deprecated */
  background?: string;
  /** @deprecated */
  textColor?: string;
  /** @deprecated, use render/children instead */
  getContent?: (dataTip: string) => React.ReactNode;
  /** @deprecated, use hidden instead */
  disable?: boolean;
  /** @deprecated */
  children?: React.ReactNode;
}

/**
 * To upgrade to V5:
 *
 * - data-for attribute now is data-tooltip-id
 * - data-tip attribute now is data-tooltip-content
 * - getContent prop was removed, use content directly instead
 * - no need to call rebuild() anymore
 * - show() and hide() can be replaced with setIsOpen()
 * - effect is now solid by default
 */
export const Tooltip = (props: TooltipProps) => {
  const {
    content,
    className,
    place,
    textColor = white,
    children,
    getContent,
    ...rest
  } = props;

  if (children || getContent) {
    // @ts-ignore - soon in v5 typing will be better
    return <StyledTooltip place={place} {...props} />;
  } else {
    return (
      <StyledTooltip
        className={className}
        resizeHide={false}
        getContent={(dataTip) => (
          <BodyUI color={textColor || white}>{content || dataTip}</BodyUI>
        )}
        // @ts-ignore - content will be added in react-tooltip v5
        content={content ?? getContent?.("")}
        {...rest}
      />
    );
  }
};

Tooltip.rebuild = ReactTooltip.rebuild;
Tooltip.hide = ReactTooltip.hide;
Tooltip.show = ReactTooltip.show;

const StyledTooltip = styled(ReactTooltip)<{
  small?: boolean;
  background?: string;
}>`
  background-color: ${({ background }) => background || gray01} !important;

  ${({ small, background }) =>
    small
      ? css`
          &.__react_component_tooltip {
            padding: 2px 8px !important;
            transition-delay: 0.5s !important;
          }

          &.place-top,
          &.place-bottom,
          &.place-left,
          &.place-right {
            &:after {
              border: none !important;
            }
          }
        `
      : css`
          &.place-top {
            &:after {
              border-top-color: ${background || gray01} !important;
              border-top-style: solid !important;
              border-top-width: 6px !important;
            }
          }

          &.place-bottom {
            &:after {
              border-bottom-color: ${background || gray01} !important;
              border-bottom-style: solid !important;
              border-bottom-width: 6px !important;
            }
          }

          &.place-left {
            &:after {
              border-left-color: ${background || gray01} !important;
              border-left-style: solid !important;
              border-left-width: 6px !important;
            }
          }

          &.place-right {
            &:after {
              border-right-color: ${background || gray01} !important;
              border-right-style: solid !important;
              border-right-width: 6px !important;
            }
          }

          &.__react_component_tooltip {
            padding: 8px 16px !important;
            transition-delay: 0.5s !important;
            z-index: var(--z-index-tooltip, 1403);
          }
        `}
`;

Tooltip.defaultProps = {
  // the "as" syntax is needed for the Stories file to avoid type errors.  it's temporary
  effect: "solid" as "float" | "solid",
  place: "top" as "top" | "right" | "bottom" | "left" | "bottom-start" | "bottom-end" | "top-start" | "top-end",
  small: false,
};
